@font-face {
font-family: '__AvenirNextedium_868e6f';
src: url(/_next/static/media/d2046d06d34102d5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
}

@font-face {
font-family: '__AvenirNextedium_868e6f';
src: url(/_next/static/media/1bfc3681dfbc9dfc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__AvenirNextedium_868e6f';
src: url(/_next/static/media/d34cc9a265b791ad-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__AvenirNextedium_868e6f';
src: url(/_next/static/media/0a6de87e078249ef-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__AvenirNextedium_868e6f';
src: url(/_next/static/media/539413029c00ae50-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: '__AvenirNextedium_868e6f';
src: url(/_next/static/media/fca430a7208d0aec-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__AvenirNextedium_868e6f';
src: url(/_next/static/media/81e7cc8950e1cfe6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
}@font-face {font-family: '__AvenirNextedium_Fallback_868e6f';src: local("Arial");ascent-override: 95.47%;descent-override: 24.97%;line-gap-override: 0.00%;size-adjust: 101.71%
}.__className_868e6f {font-family: '__AvenirNextedium_868e6f', '__AvenirNextedium_Fallback_868e6f'
}.__variable_868e6f {--font-avenirnext: '__AvenirNextedium_868e6f', '__AvenirNextedium_Fallback_868e6f'
}

@font-face {
font-family: '__GothamRounded_6c596f';
src: url(/_next/static/media/9079a99665726f04-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__GothamRounded_6c596f';
src: url(/_next/static/media/77011aec4d5bcbcb-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__GothamRounded_6c596f';
src: url(/_next/static/media/d51a31c3c079abbd-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__GothamRounded_6c596f';
src: url(/_next/static/media/d34cc9a265b791ad-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}@font-face {font-family: '__GothamRounded_Fallback_6c596f';src: local("Arial");ascent-override: 69.59%;descent-override: 17.40%;line-gap-override: 17.40%;size-adjust: 114.96%
}.__className_6c596f {font-family: '__GothamRounded_6c596f', '__GothamRounded_Fallback_6c596f'
}.__variable_6c596f {--font-gotham: '__GothamRounded_6c596f', '__GothamRounded_Fallback_6c596f'
}

